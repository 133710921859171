import Head from 'next/head'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { getStaticPaths, makeStaticProps } from 'lib/getStatic'
import MainLayout from 'components/layouts/MainLayout'
import nextI18nextConfig from 'next-i18next.config'

export default function Index() {

  const { t } = useTranslation('index')

  const router = useRouter()
  const currentLocale = router.query.locale || nextI18nextConfig.i18n.defaultLocale

  useEffect(() => {
    document.documentElement.lang = currentLocale.toString()
  }, [currentLocale])

  return (
    <>
      <Head>
        <title>{t('title')}</title>
        <meta name = "description" content = {t('description')} />
        <meta name = "viewport" content = "width=device-width, initial-scale=1" />
        <meta httpEquiv = "content-language" content = {currentLocale.toString()} />
        <link rel = "shortcut icon" type = "image/x-icon" href = "favicon.ico?" />
        <meta name = "robots" content = "index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1" />
        <link rel = "alternate" hrefLang = "de" href = "https://www.kroemtech.ch/de/" />
        <link rel = "alternate" hrefLang = "en" href = "https://www.kroemtech.ch/en/" />
        <link rel = "alternate" hrefLang = "x-default" href = "https://www.kroemtech.ch/de/" />
      </Head>
      <main
        style = {{ fontFamily: 'Verdana, sans-serif' }}
      >
        <MainLayout
          view = "index"
        />
      </main>
    </>
  )
}

const getStaticProps = makeStaticProps(['common', 'index'])
export { getStaticPaths, getStaticProps }

/*
className = {`${styles.main} ${inter.className}`
*/
